<template>
  <div class="audio">
    <div class="title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/goods/analyse' }"
          >课程列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ breadcrumb }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form
        :model="audioForm"
        :rules="rules"
        ref="rulForm"
        class="demo-ruleForm"
      >
        <div class="form-compo">
          <el-form-item label="课程名称：" prop="lesson_name">
            <el-input
              class="form-input"
              size="small"
              v-model="audioForm.lesson_name"
            ></el-input>
          </el-form-item>
          <div class="form-compo-title">
            <div>课件</div>
            <div class="form-compo-title-img" @click="addVideo">
              <span>添加课件</span>
              <el-image
                class="img"
                :src="require(`../../../assets/image/add.png`)"
              ></el-image>
            </div>
          </div>
          <div
            class="form-center"
            v-for="(value, index) in audioForm.videoList"
            :key="value.shop_lesson_video_id"
          >
            <div class="form-center-div">
              <el-form-item label="名称：" :prop="'videoList' + index">
                <el-input
                  class="form-input-small"
                  size="small"
                  v-model="value.video_name"
                ></el-input>
              </el-form-item>
            </div>
            <div class="form-center-div" v-if="isRefresh">
              <el-button type="primary" @click="addLongPic(index)"
                >上传文件</el-button
              >
              <span
                v-if="value.video_url !== '' ? true : false"
                class="pic-audio"
                >上传成功</span
              >
            </div>
            <div>
              <el-image
                class="form-center-img"
                @click="removeVideo(value)"
                v-if="index !== 0"
                :src="require(`../../../assets/image/del.png`)"
              ></el-image>
            </div>
          </div>
          <el-form-item label="课程简介：">
            <el-input
              type="textarea"
              class="form-input"
              v-model="audioForm.lesson_cover"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm"
              >确定</el-button
            >
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <!-- 弹窗 -->
    <MyDialog
      :dialogVisible="dialogVisible"
      @changeDialog="changeDialog"
      @getPic="getPic"
      :limit="limit"
      :video_id="video_id"
    />
  </div>
</template>

<script>
import MyDialog from "../../../components/MyDialog.vue";
import { shopAudioDetails, addAudio } from "../../../request/api";

export default {
  components: {
    MyDialog,
  },
  data() {
    return {
      breadcrumb: "新增课程",
      audioForm: {
        lesson_name: "",
        lesson_cover: "",
        shop_lesson_id: "",
        videoList: [
          {
            video_name: "",
            video_url: "",
            shop_lesson_video_id: Date.now(),
          },
        ],
      },
      rules: {
        lesson_name: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
      },
      isRefresh: true,
      dialogVisible: false,
      limit: 0,
      video_id: 0,
    };
  },
  methods: {
    async submitForm() {
      this.$refs.rulForm.validate((valid) => {
        if (!valid) {
          console.log("error submit!");
          return false;
        }
      });
      if (this.audioForm.lesson_name === "") {
        this.$message.warning("请完善字段信息");
        return;
      }

      this.audioForm.videoList = JSON.stringify(
        this.audioForm.videoList.map((element) => {
          // 下标为0 或者-1 表示扩展名不存在。则把他转成一个很大的数
          const extension = element.video_url.slice(
            ((element.video_url.lastIndexOf(".") - 1) >>> 0) + 2
          );
          let video_extension = 0;
          if (extension === "mp4" || extension === "MP4") {
            video_extension = 1;
          }
          return {
            shop_lesson_video_id: element.shop_lesson_video_id,
            video_name: element.video_name,
            video_url: element.video_url,
            video_extension: video_extension,
          };
        })
      );

      const res = await addAudio(this.audioForm);
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.$message.success("添加成功");
      this.$router.push({ path: "/goods/analyse" });
    },
    // 上传文件 限制1个文件
    addLongPic(index) {
      this.video_id = index;
      this.limit = 1;
      this.dialogVisible = true;
    },
    resetForm() {
      this.$router.back();
    },
    // 取消按钮 关闭弹窗
    changeDialog() {
      this.dialogVisible = false;
    },
    // 组件保存文件的回调
    getPic(picArr, id, video_id) {
      this.dialogVisible = false;
      picArr.forEach((pic) => {
        if (id === 1) {
          this.audioForm.videoList[video_id].video_url = pic;

          this.isRefresh = false;
          this.$nextTick(() => {
            this.isRefresh = true;
          });
        }
      });
    },
    // 新增课件
    addVideo() {
      this.audioForm.videoList.push({
        video_name: "",
        video_url: "",
        shop_lesson_video_id: Date.now(),
      });
    },
    // 删除课件
    removeVideo(value) {
      let index = this.audioForm.videoList.indexOf(value);
      if (index !== -1) {
        this.audioForm.videoList.splice(index, 1);
      }
    },
    // 初始化操作
    async init() {
      if (this.audioForm.shop_lesson_id !== undefined) {
        this.breadcrumb = "编辑课程";
        const res = await shopAudioDetails(this.audioForm.shop_lesson_id);
        if (res.status !== 200 || res.data.code !== "1000") {
          this.$message.error(res.data.msg);
          return;
        }
        this.audioForm.lesson_name = res.data.data.lesson_name;
        this.audioForm.lesson_cover = res.data.data.lesson_cover;
        this.audioForm.videoList = res.data.data.videoList;
      }
    },
  },
  created() {
    this.audioForm.shop_lesson_id = this.$route.query.shop_lesson_id;
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.audio {
  margin: 10px;
  height: 100%;
  .title {
    padding: 10px;
    background: #fff;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    margin-bottom: 10px;
  }
  .myform {
    height: 100%;
    .demo-ruleForm {
      height: 100%;
      .form-compo {
        background: #fff;
        padding: 22px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        .form-input {
          width: 338px;
        }
        .form-compo-title {
          border-bottom-style: solid;
          border-bottom-color: #c4c4c4;
          border-bottom-width: 2px;
          width: 430px;
          display: flex;
          margin-bottom: 8px;
          justify-content: space-between;
          font-size: 14px;
          font-weight: 400;
          .form-compo-title-img {
            color: #0062c2;
            display: flex;
            width: 76px;
            cursor: pointer;
            .img {
              width: 14px;
              height: 14px;
              margin-top: 3px;
              margin-left: 2px;
            }
          }
        }
        .form-input-small {
          width: 150px;
        }
        .form-center {
          display: flex;
          .form-center-div {
            width: 228px;
            .pic-audio {
              margin-left: 10px;
              color: rgb(255, 0, 0);
              font-size: 16px;
            }
          }
          .form-center-img {
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
      .form-button {
        background: #fff;
        padding: 20px 0px 2px 20px;
        margin: 10px 0px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
}
</style>
